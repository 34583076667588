.invitation {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  width: 100%;
}

.error {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.error-text {
  margin: 10px auto;
}

.frame {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.confirm-container {
  margin: auto;
}

.notice {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 90vw;
}

.error-text {
  color: rgb(253, 109, 109);
}

.searchbox {
  background-color: rgb(216, 216, 216);
  border-radius: 3px;
  border: none;
  padding: 5px 6px 4px 6px;
  line-height: 1.5em;
}

.guests {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.search {
  display: flex;
  flex-direction: column;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.cursor-pointer {
  cursor: pointer;
}

.fade {
  animation: cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s forwards fade;
  opacity: 0;
}

.delay-1 {
  animation-delay: 0.3s;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(182, 41, 170);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@-moz-keyframes fade {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  to {
    opacity: 1;
  }
}

@keyframes fade {
  to {
    opacity: 1;
  }
}
