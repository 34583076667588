body {
  margin: 0;
  font-family: "Ubuntu Mono", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, pink, white, transparent);
}

code {
  font-family: "Ubuntu Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.invitation-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.invitation-description {
  /* background-image: url(./assets/tree.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 70vh; */
  flex-grow: 1;
  height: calc(100vh - 80px);
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.centered {
  margin: auto;

  display: flex;
  flex-direction: column;

  max-width: 70vw;
}

.send {
  background-color: #e4e4e4;
  border: none;
  border-radius: 3px;
  padding: 5px 6px 4px 6px;
  line-height: 1.5em;
  cursor: pointer;
}

.send.confirm {
  color: green;
}

.send:disabled {
  background-color: rgb(207, 207, 207);
  color: gray;
  cursor: not-allowed;
}
