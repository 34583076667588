.admin {
  margin: auto;
}

.inv {
  border-bottom: 2px solid #2c2c2c;
  margin: 10px 10px 100px 10px;
}

.guest {
  min-width: 400px;
  display: flex;
  justify-content: space-between;
}

.guest > h2 {
  margin: auto 0;
}

.guest > span {
  margin: auto 0;
}
