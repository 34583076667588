.search-container {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.search {
  margin: auto;
  padding: 10px;
}

.search-input-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
