.name {
  text-transform: capitalize;
}

.confirm-box {
  display: flex;
  flex-direction: column;
  min-width: 40vw;
  border-radius: 6px;
  margin: 16px;
}

/* @media only screen and (min-width: 800px) {
  .confirm-box {
    background-color: #ffffff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  }
} */

.content {
  display: flex;
}

.message-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.message {
  border: none;
  margin: 10px 0;
  padding: 6px;
  resize: vertical;
  max-height: 600px;
  min-height: 200px;
}

.footer {
  display: flex;
  margin-left: auto;
}

.decide {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-right: auto;
}
